















































































































import {Vue, Component, PropSync, Ref} from 'vue-property-decorator';
import User from "@/models/User";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import ConfigModule from "@/store/ConfigModule";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import UserService from "@/services/UserService";

@Component({})
export default class UserCardComponent extends Vue {

  @PropSync('user') syncedUser!: User
  @Ref() readonly form!: HTMLFormElement

  get configs() { return getModule(ConfigModule).configs }
  lang: any = getModule(LangModule).lang

  changePasswordDialog: boolean = false
  password: string = ""
  newPassword: string = ""
  repeatPassword: string = ""
  showPassword: boolean = false
  showNewPassword: boolean = false

  newPasswordRules = [
    (v: string) => v.length > 0 ? true : this.lang.newPasswordRequired,
    (v: string) => v.length >= 8 ? true : this.lang.password8CharactersRequired
  ]

  deleteUser() {
    this.$emit("delete")
  }

  openChangePasswordDialog() {
    if (this.form) this.form.resetValidation()
    this.changePasswordDialog = true
    this.password = ""
    this.newPassword = ""
    this.repeatPassword = ""
    this.showPassword = false
    this.showNewPassword = false
  }

  changePassword() {
    if (this.form.validate()) {
      getModule(DialogModule).showDialog(new Dialog(
          this.lang.warning, this.lang.passwordUpdateQuestion,
          () => UserService.patchChangePassword(this, this.password, this.newPassword, Number(this.$route.params.id))
      ))
    }
  }

  activateCrm() {

  }

  deactivateCrm() {

  }

}
