import axios from "axios";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Headquarter from "@/models/Headquarter";
import Response from "@/models/responses/Response";
import User from "@/models/User";

export default class HeadquarterManagerService {

    static async getById(id: number): Promise<Response<User>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/user/headquarter-managers/${id}`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarterManager = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: headquarterManager })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async findAll(page: number, size: number): Promise<Response<User[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/user/headquarter-managers`, {
                params: { page, size },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeArray(response.data, User)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async create(
        email: string, name: string, lastname: string, password: string, commercialName: string
    ): Promise<Response<User>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + `/api/user/headquarter-managers`, null, {
                params: {
                    email, name, lastname, password, commercialName
                },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const commercial = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercial })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async delete(id: number) {
        try {
            const response = await axios.delete(ConstantTool.BASE_URL + `/api/user/headquarter-managers/${id}`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)

        }
    }

}