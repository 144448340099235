




































































import {Vue, Component} from 'vue-property-decorator';
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import User from "@/models/User";
import Handler from "@/handlers/Handler";
import HeadquarterManagerService from "@/services/HeadquarterManagerService";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import ConfigModule from "@/store/ConfigModule";
import UserCardComponent from "@/components/UserCardComponent.vue";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import CommercialService from "@/services/user/CommercialService";
import UserFileTab from "@/components/tabs/UserFileTab.vue";

@Component({
  components: {UserFileTab, UserCardComponent}
})
export default class HeadquarterManagerView extends Vue {

  get configs() { return getModule(ConfigModule).configs }

  tab: number = 0
  loading: boolean = false
  lang: any = getModule(LangModule).lang
  headquarterManager: SingleItem<User> = { item: new User() }

  created() {
    this.refresh()
  }

  async refresh() {
    await Handler.getItem(this, this.headquarterManager, () =>
        HeadquarterManagerService.getById(Number(this.$route.params.id))
    )
  }

  setActive() {

  }

  async deleteHeadquarter() {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, this.lang.deleteUserConfirmation, async () => {
          await HeadquarterManagerService.delete(Number(this.$route.params.id))
          await this.$router.push('/headquarter-managers')
        }

    ))

  }

  openChangePasswordDialog() {
  }

  activateCrm() {

  }

  deactivateCrm() {

  }

}
